import React, { useState } from "react";
import "./app.css";
import icon from "./assets/icon.avif";
import Movie from "./components/movie/movie";
import Movieinfo from "./components/movieinfo/movieinfo";
import Axios from "axios";
export const API_KEY = "bd240564";
function App() {
  const [searchQuery, setSearchQuery] = useState();
  const [timeoutid, setTimeoutid] = useState();
  const [movielist, updateMovielist] = useState([]);
  const [selectedmovie, onMovieselect] = useState();
  const fetchData = async (searchString) => {
    const response = await Axios.get(
      `https://www.omdbapi.com/?s=${searchString}&apikey=${API_KEY}`
    );
    updateMovielist(response.data.Search);
  };
  const setTextchange = (event) => {
    clearTimeout(timeoutid);
    setSearchQuery(event.target.value);
    const timeout = setTimeout(() => {
      fetchData(event.target.value);
    }, 500);
    setTimeoutid(timeout);
  };
  return (
    <>
      <div className="header">
        <div className="appname">
          <span>Movie Hub</span>
        </div>
        <div className="searchbox">
          <img src={icon} alt="" />
          <input
            type="text"
            name=""
            id=""
            placeholder="Search Movie"
            value={searchQuery}
            onChange={setTextchange}
          />
        </div>
      </div>
      {selectedmovie && <Movieinfo selectedmovie={selectedmovie} onMovieselect={onMovieselect}/>}
      <div className="movielist-container">
        {movielist.length
          ? movielist.map((movie, index) => (
              <Movie key={index} movie={movie} onMovieselect={onMovieselect} />
            ))
          : "Search any Movie"}
      </div>
    </>
  );
}

export default App;
